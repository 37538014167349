$nav-height: 4em !default;

@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // ma

.site-logo img {
  max-height: 4rem;
}
